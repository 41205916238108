import React, { useEffect, useRef, useState } from 'react';
import './App.css';

function Countdown() {
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSecounds] = useState('00');

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date('August 30, 2022 00:00:00').getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60* 24));
      const hours = Math.floor(distance % (1000 * 60 * 60* 24) / (1000 * 60 * 60));
      const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
      const seconds = Math.floor(distance % (1000 * 60 ) / 1000);

      if (distance < 0){
        //stop our timer
        clearInterval(interval.current);
      } else {
        //update timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSecounds(seconds);
      }

    }, 1000);
  };

  //componentDidMount
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <div className="App">
      <header className="App-header">
        <section className='timer-container'>
          <section className='timer'>
            <div>
            <section>
                <p>{timerDays}</p>
                <p><small>Days</small></p>
              </section>
              <span>:</span>
              <section>
                <p>{timerHours}</p>
                <p><small>Hours</small></p>
              </section>
              <span>:</span>
              <section>
                <p>{timerMinutes}</p>
                <p><small>Minutes</small></p>
              </section>
              <span>:</span>
              <section>
                <p>{timerSeconds}</p>
                <p><small>Seconds</small></p>
              </section>
            </div>
          </section>
        </section>
      </header>
    </div>
  );
}

export default Countdown;

